<template>
<div id="app" class="app app-full-height app-without-header">
    <div class="login">
        <div class="login-content">
            <form @submit.prevent="requestgetverifycode" name="login_form">
            <h1 class="text-center">Forgot Password</h1>
            <div class="text-muted text-center mb-4">
            You Forgot your Password
            </div>
            <div class="mb-3">
            <label class="form-label">Phone Number</label>
            <input type="number" v-model="forminput.phone" class="form-control form-control-lg fs-15px" placeholder="Enter your Number" />
            <div v-if="formerror.phone" class=" btn-sm btn-danger"  >{{ formerror.phone}}</div>
            <div v-if="msg.phone" class=" btn-sm btn-danger text-center" >{{JSON.stringify(msg.phone).replaceAll("[", "").replaceAll("]", "").replaceAll('"', "") }}</div>
            
            </div>
            <button v-if="loading == 'getverifycode'" class="btn btn-outline-primary btn-block" style="width:100%" >
                <span  class="spinner-grow spinner-grow-sm" role="status"></span> Sending Code..
            </button>
            <button v-else type="submit" class="btn btn-primary btn-lg d-block w-100 fw-500 mb-3">Request Reset Code</button>
            <div class="text-center text-muted">
            I remember my Password Now <router-link :to="{ name: 'login' }">Login Now</router-link>
            </div>
            </form>
        </div>
    </div>
</div>

<a href="#" data-click="scroll-top" class="btn-scroll-top fade"><i class="fa fa-arrow-up"></i></a>

</template>

<script>
import {mapGetters, mapActions } from 'vuex'

export default {
    data(){
        return{
            forminput:{
                phone:'',
                password:'',
                remember_me:''
            },


            formerror:{
                phone:'',
                password:'',
                remember_me:''
            },



        }
    },

    computed:{
        ...mapGetters(['msg','loading'])
    },

    methods:{
        ...mapActions(['getverifycode']),

        requestgetverifycode(){
            if(this.forminput.phone == ''){
                this.formerror.phone = 'Phone Number  Required';
                setTimeout(() => this.formerror.phone = '', 3000)
            }else{
                let data ={
                    phone:this.forminput.phone
                }
                this.getverifycode(data).then(()=>{

                })
            }
        }
        
    }
}
</script>